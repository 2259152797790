import { z } from 'zod'

const emptyAmountSchema = z.literal('')
const zeroSchema = z.literal('0')
const nonZeroIntegerSchema = z
  .string()
  .regex(/^[1-9][0-9]*$/, 'Values greater than or equal to "1" may not start with "0"')
/**
 *
 * @param maxDecimals 1 or more
 */
const createFloatSchema = (maxDecimals: number) =>
  z
    .string()
    .regex(
      new RegExp(`^[0-9]+\\.[0-9]{0,${maxDecimals}}$`),
      'Floating point numbers may not start with "."',
    )
export const createAmountSchema = (maxDecimals: number) =>
  z.union([emptyAmountSchema, zeroSchema, nonZeroIntegerSchema, createFloatSchema(maxDecimals)])
